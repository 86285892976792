@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables';

.fees {
  background: $lighter-color;
  border: $darkBorder;
  border-radius: $box-radius;
  flex-grow: 1;
  margin: 0 $smallMargin;
  padding: 10px;

  .title {
    font-size: $regular;
    font-weight: bold;
  }

  .subtitle {
    margin-bottom: 0;

    span {
      font-weight: normal;
    }
  }

  .description {
    font-size: $small;
    margin-top: $margin;
  }

  .credit {
    min-width: 50%;
  }

  .table {
    border: none;
    box-shadow: none;
    .rt-thead {
      background-color: transparent;
    }
  }
}
