@import '../../../Styles/settings/variables';
@import '../../../Styles/mixins/mixins';

.notification-container {
  -webkit-transition: bottom 500ms;
  -moz-transition: bottom 500ms;
  -ms-transition: bottom 500ms;
  -o-transition: bottom 500ms;
  transition: bottom 500ms;
  position: fixed;
  bottom: 20px;
  right: 15px;
  left: 15px;
  z-index: 90;
  padding: $middlePadding $padding;
  border-radius: $box-radius;
  box-shadow: $card-box-shadow;

  &.closed {
    bottom: -1000px;
  }

  @include mq(tablet) {
    top: initial;
    left: initial;
    right: 5%;
  }

  &.request {
    p {
      text-align: center;
      color: $lighter-color;
      margin: 0;
      font-size: $bigger;

      @include mq('tablet') {
        font-size: $big;
      }
    }
  }

  &.export-list {
    padding: 0;
    box-shadow: none;

    &.mobile {
      -webkit-transition: right 500ms, left 500ms;
      -moz-transition: right 500ms, left 500ms;
      -ms-transition: right 500ms, left 500ms;
      -o-transition: right 500ms, left 500ms;
      transition: right 500ms, left 500ms;
      display: flex;
      left: 5%;
      right: 5%;

      &.hide {
        left: calc(100% - 30px);
        right: -220%;
        bottom: 20px;
      }

      &.closed {
        left: 100%;
        right: -200%;
      }

      .toggle-mobile-container {
        @include flexBox(center, center);
        width: 30px;
        height: 100%;
        color: $lighter-color;
        transition: width 500ms;

        &.hide {
          width: 0;

          svg {
            display: none;
          }
        }
      }
    }

    .export-status-container {
      .status {
        @include flexBox(center, center);

        font-weight: bold;
        -webkit-border-radius: $box-radius;
        -moz-border-radius: $box-radius;
        border-radius: $box-radius;

        &.success {
          cursor: pointer;
        }

        &.error {
          color: $error-color;
        }

        .pending {
          color: $warning-color;
        }

        &.action {
          background-color: rgba($dark-color, 0.1);
          padding: $smallerPadding;
          flex-direction: row-reverse;
        }
      }
    }

    .Collapsible {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      .export-list-header {
        @include flexBox(space-between, center);

        .buttons-container {
          display: flex;

          .notification-buttons {

            &:last-child {
              margin-left: $spaceMargin;
            }
            padding: 0 $smallPadding;
          }
        }

        span {
          color: $lighter-color;
          margin: 0;
          font-weight: bold;
          font-size: $big;
        }

        svg {
          color: $lighter-color;
        }
      }

      .Collapsible__trigger{
        &.is-closed, &.is-open {
          padding: $middlePadding $smallPadding $middlePadding $padding;
          border-radius: $card-border-radius $card-border-radius 0 0;
        }
      }

      .Collapsible__contentOuter {
        .Collapsible__contentInner {
          border-radius: 0 0 $box-radius $box-radius;

          .export-item {
            @include flexBox(space-between, center);
            padding: $smallPadding $padding;
            min-height: 50px;

            @include mq('tablet') {
              min-width: 330px;
              max-width: 380px;
            }

            .export-name {
              margin-right: $margin;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 1;
            }
          }

          .link-item {
            padding: $smallPadding $padding;
            text-align: center;
            font-weight: bold;
          }
        }
      }
    }
  }

  .header-error {
    background-color: $error-color !important;
  }
}
