.error-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  span {
    margin-top: 20px;
    font-size: 16px;
  }
}