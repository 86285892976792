@import "src/Styles/settings/variables";
@import "src/Styles/mixins/mixins";

.date-filter-wrapper {
  &.empty {
    .rdrDateDisplay {
      position: relative;
      .rdrDateDisplayItem {
        visibility: hidden;
        input {
          color: transparent !important;
        }
      }

      &:after {
        content: 'Selecione uma data abaixo';
        text-align: center;
        color: $dark-color;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    .rdrDay {
      .rdrStartEdge, .rdrEndEdge, .rdrInRange {
        + .rdrDayNumber {
          span {
            color: $lighter-color !important;
          }
        }
      }
      &:not(.rdrDayPassive) {
        .rdrDayNumber {
          span {
            color: $dark-color;
          }
        }
      }
    }
  }

  .rdrDateDisplay {
    height: 40px;
  }

  .rdrDateDisplayItem {
    @include flexBox(center, center);
    background: transparent;
    border: 0 none !important;
    box-shadow: none;

    input {
      cursor: default;
      outline: none;
    }
  }
}
