@import 'src/Styles/settings/variables';
@import 'src/Styles/mixins/mixins';

.drop-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  outline: none;
  border-width: 1px;
  border-color: $light-color3;
  border-style: dashed;
  border-radius: $box-radius;
  font-weight: bold;
  color: rgba($dark-color, 0.7);
  flex: 1;

  &.error {
    border-color: $error-color;
  }

  .input-zone {
    display: flex;
    flex-direction: column;
    margin: 0 $margin;

    .error-message {
      color: $error-color;
    }
  }

  .preview {
    max-width: 100%;
  }
}




