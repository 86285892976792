@import 'src/Styles/mixins/mixins.scss';
@import "src/Styles/settings/variables.scss";

@media only screen and (max-width: 400px) {
  .filter{
    display: none;
  }
}

.mobile-filter {
  &-content {
    margin: $smallMargin 0;

    &.checkbox, &.radio {
      .dropdown-list {
        display: flex;
        flex-wrap: wrap;

        li {
          padding-right: $smallPadding;

          input[type="checkbox"], input[type="radio"] {
            display: none;
          }

          label {
            border: solid 1px $light-color;
            border-radius: $box-radius;
            padding: $smallerPadding $smallPadding;
          }

          &.checked {
            label {
              color: $lighter-color;
            }
          }
        }
      }
    }
  }

  &-modal {
    border-radius: 0 !important;
    margin: 0;
    height: 100%;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-background-clip: no-clip;
    -moz-background-clip: no-clip;
    background-clip: no-clip;

    .label {
      font-weight: bold;
      font-size: $big;
    }

    .content {
      padding-bottom: $padding;
    }

    &-overlay {
      padding: 0;
      background-color: $lighter-color;
    }

    .dropdown-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      box-shadow: $shadow;
      background-color: $lighter-color;
      padding: $smallPadding;

      .btn {
        flex: 1;
        &.clean {
          margin-right: $spaceMargin;
        }
      }
    }
  }
}

.filter {
  margin-bottom: $margin;
  @include flexBox(flex-start, center);
  flex-wrap: wrap;

  .title{
    font-size: 1em;
    float: left;
    margin-right: $spaceMargin;
    margin-top: $spaceMargin;
  }
  .content {
    width: 100%;
    @include flexBox(flex-start, center);
    flex-wrap: wrap;

    @include mq(phone-wide) {
      width: auto;
    }

    .filter-label {
      margin: 0 $spaceMargin;
      font-weight: bold;
    }
  }

  .icon {
    align-self: flex-start;
    width: $iconSize;
    cursor: pointer;

    @include mq(phone-wide) {
      cursor: initial;
      align-self: center;
    }
  }

  .clean {
    float: left;
    font-weight: bold;
  }

  > hr {
    border: 0 none;
    border-bottom: 1px solid $primary-color;
    margin: $smallMargin 0;
  }

  &-box {
    margin: 0 0 $margin 0;
    list-style: none;
    width: 100%;

    .collapsible-content {
      &-outer {
        background-color: $lighter-color;

        .date-filter-wrapper {
          margin: $smallMargin 0;
          display: flex;
          flex-direction: column;
        }
      }

      &-inner {
        background-color: $lighter-color;
        padding: $smallPadding !important;

        .dropdown-footer {
          margin-bottom: $smallMargin;
          :first-child {
            margin-right: $smallMargin;
          }
        }
      }
    }

    &.applied {
      border-radius: $box-radius;

      .dropdown {
        &.show {
          .dropdown-toggle {
            color: $lighter-color;
          }
        }
      }
    }

    svg {
      margin-right: 0.75rem;
      cursor: pointer;
      path {
        fill: $lighter-color;
      }
    }

    @include mq(phone-wide) {
      margin: 0 0 0 $smallMargin;
      width: auto
    }

    .dropdown-list {
      list-style: none;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 0;

      @include mq(tablet) {
        min-width: $menuWidth;
      }

      input {
        margin-right: $spaceMargin;
        @include mq(phone-wide) {
          margin-right: $smallMargin;
        }
      }

      li {
        label {
          color: $dark-color;
          margin-bottom: $smallMargin;
        }
      }
    }

    .dropdown-menu {
      padding: $smallPadding;

      &.show {
        margin-top: $smallMargin;
      }

      .date-filter {
        &-wrapper {
          flex-wrap: wrap;

          @include mq(tablet-wide) {
            flex-wrap: nowrap;
          }

          .rdrDefinedRangesWrapper {
            width: 100%;

            @include mq(tablet-wide) {
              min-width: 226px;
            }
          }
        }
      }
    }

    .dropdown {
      border: solid 1px;
      border-radius: $box-radius;

      &:hover {
        svg path {
          fill: $lighter-color;
        }

        .dropdown-toggle {
          color: $lighter-color;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none !important;
      }

      &:hover {
        &.show {
          .dropdown-toggle {
            color: $lighter-color;
          }
        }
      }

      &.show > .dropdown-toggle {
        background-color: transparent !important;

        &:focus {
          outline: none;
          box-shadow: none !important;
        }
      }

      .dropdown-toggle {
        border: none;

        &:hover {
          background-color: transparent;
        }

        &:focus {
          outline: none;
          box-shadow: none !important;
        }

        &:after {
          display: none;
        }
      }

      .svg-inline--fa {
        vertical-align: middle;
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        float: right;

        .clean-filter {
          color: rgba($dark-color, 0.7) !important;
          padding-left: 0;
        }
      }
    }
  }

  .Collapsible {
    background: transparent;
    width: 100%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .Collapsible__trigger {
    border: none;
    padding: 0;
    color: transparent;
    background: transparent;
    border-radius: 0;

    svg path {
      fill: $darker-color;
    }
  }

  .Collapsible__contentInner {
    border: none;
    padding: $smallPadding 0;
    background-color: transparent;
  }
}

.footer-buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 0 $smallerPadding;
}

.badge-filter {
  background-color: $lighter-color !important;
  opacity: 0.8;
}
