@import '../../../Styles/settings/variables';
@import '../../../Styles/mixins/mixins';

.queued-export-disclaimer {
  background-color: $btn-secondary-color;
  border-radius: $box-radius;
  text-align: center;
  padding: $smallPadding;
  display: flex;
  justify-content: space-between;
  font-size: $small;
  position: relative;
  width: 190px;
  -webkit-transition: width 0.5s ease-in 0.6s; /* For Safari 3.1 to 6.0 */
  transition: width 0.5s ease-in 0.6s;

  .status {
    width: 0;
    -webkit-transition: width 0.2s ease-in 1.1s;
    transition: width 0.2s ease-in 1.1s;
  }

  .text {
    -webkit-transition: height 0.2s ease-in 1.1s;
    transition: height 0.2s ease-in 1.1s;
    overflow: hidden;
    height: 18px;
  }

  &.done {
    width: 30px;
    justify-content: center;

    .progress-bar {
      width: 0;
    }

    .text {
      height: 0;
    }

    .status {
      @include flexBox(center, center);

      font-weight: bold;
      -webkit-border-radius: $box-radius;
      -moz-border-radius: $box-radius;
      border-radius: $box-radius;
      width: 1em;

      &.success {
        cursor: pointer;
      }

      &.error {
        color: $error-color;
      }

      .pending {
        color: $warning-color;
      }
    }
  }

  .request-loading {
    z-index: unset;
    position: relative;
    background-color: unset;
    margin-left: $smallMargin;

    .loader {
      position: relative;
      padding: unset;

      div {
        width: 6px;
        height: 6px;
        margin: 0 2px;
      }
    }
  }

  .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    -webkit-transition: width 0.5s ease-in 1.3s;
    transition: width 0.5s ease-in 1.3s;

    .react-sweet-progress-line-inner {
      min-height: 3px;
    }
  }

  .symbol {
    display: none;
  }

  &.error {
    background-color: rgba($error-color, 0.1);
    color: $error-color;
    justify-content: center;
  }
}
