@import '../../../Styles/settings/variables';
@import '../../../Styles/mixins/mixins';

.empty-state-container {
  text-align: center;
  margin: $largeMargin auto;

  p {
    margin: 0;
  }

  .icon {
    margin-bottom: $margin;

    svg {
      max-width: 100px;
      max-height: 100px;
    }
  }

  .title {
    font-size: $biggest;
    font-weight: bold;
    color: $dark-color;
    margin-bottom: $smallMargin;
  }

  .description {
    font-size: $big;
    color: $dark-color;

    a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  .action {
    margin-top: $bigMargin;
  }
}
