@import '../../../../Styles/settings/variables';
@import '../../../../Styles/mixins/mixins';

.form-group {
  &.password-digit {
    .inputs-wrapper {
      @include flexBox(flex-start, center);

      input {
        text-align: center;
        width: 35px;
        margin-right: $spaceMargin;
      }
    }
  }
}
