.mobile-date-range {
  width: 100%;

  .rdrNextPrevButton {
    outline: none;
  }

  .rdrMonth {
    width: 100%;
  }
}