@import '../../../../Styles/settings/variables';

.password {
  &-container {
    position: relative;
  }
  &-eye {
    opacity: 0.3;
    cursor: pointer;
    color: $dark-color;
    position: absolute;
    right: 5%;
    bottom: 0;
    height: 100%;
  }
}
