@import '../../../Styles/settings/variables';
@import '../../../Styles/mixins/mixins';

.export-modal {
  @include mq('tablet') {
    max-width: unset !important;
    width: unset !important;
    flex: unset !important;
  }

  &.mobile-modal {
    .header {
      margin-bottom: $largeMargin;
    }
  }

  .content {
    .filter-container {
      -webkit-border-radius: $box-radius;
      -moz-border-radius: $box-radius;
      border-radius: $box-radius;
      margin-bottom: $smallMargin;

      p {
        color: $dark-color;
        margin-bottom: 12px;
      }

      span {
        color: $dark-color;
      }
    }

    .applied-filter-container {
      border-radius: $box-radius;
      border: solid 1px rgba(85, 85, 85, 0.3);
      padding: 8px $smallPadding;
      display: inline-block;
      margin-right: $smallMargin;
      margin-bottom: $smallMargin;

      @include mq(tablet) {
        padding: 6px $smallPadding;
      }
    }

    .file-format-container {
      margin-top: $smallMargin;
      margin-bottom: 40px;

      .file-format-text {
        font-weight: bold;
      }

      .inputs-wrapper {
        :not(:last-child) {
          margin-right: $smallMargin;

          @include mq(tablet-wide) {
            margin-right: $margin;
          }
        }

        .radio-input {
          display: inline-block;

          input {
            margin-right: $spaceMargin;
          }
        }

        .btn {
          font-weight: normal;
          &:not(:last-of-type) {
            margin-bottom: $margin;
          }

          &.selected {
            color: $lighter-color;
          }
        }
        @include mq('tablet') {
        }
      }
    }
  }

  .footer {
    margin-top: $margin;
  }
}
