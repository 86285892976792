@import "src/Styles/settings/variables.scss";

.create-bank-account-template {
  .routing {
    &-wrapper {
      margin-bottom: $middleMargin;
    }

    &-number {
      display: inline-block;
      width: 70%;
      margin-right: $smallMargin;
    }

    &-check-digit {
      display: inline-block;
      width: calc(30% - #{$middleMargin});
    }
  }
}
