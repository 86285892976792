.input-filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  input {
    border: solid 1px;
    border-radius: 4px;
    padding: 5px;
  }
}